import { defineStore } from 'pinia'
import { useTemplatesStore } from './templates'

export const useMediaEditorStore = defineStore({
    id: 'mediaEditor',
    state(){
        return {
            scriptTag: null,
            userAccessToken: null,
            templates: [],
            projects: [],
            activeEditor: null,
        }
    },
    actions: {
        async loadMediaEditorUserToken(){
            try {
                const userAccessToken = await this.$nuxt.$axios.$get('design_huddle/user_token')
                this.userAccessToken = userAccessToken.token
            } catch (err) {
                console.error(err)
            }
        },
        async loadMediaEditorTemplates(){
            try {
                const templates = await this.$nuxt.$axios.$get('design_huddle/templates')
                this.templates = templates.templates?.items ?? []
            } catch (err) {
                console.error(err)
            }
        },
        async loadMediaEditorProjects(){
            try {
                const projects = await this.$nuxt.$axios.$get('design_huddle/projects')
                this.projects = projects.projects?.items ?? []
            } catch (err) {
                console.error(err)
            }
        },
        async exportProject({projectId}){
            const templatesStore = useTemplatesStore()
            try {
                const exportScheduled = await this.$nuxt.$axios.$get(`design_huddle/project/${projectId}/export`)
                setTimeout(async () => {
                    await templatesStore.loadTemplates()
                }, 12000)
                return exportScheduled
            } catch (err) {
                console.error(err)
            }
        },
        async getExportUrl({projectId}){
            try {
                const res = await this.$nuxt.$axios.$get(`design_huddle/project/${projectId}/inline_export`)
                if(res.success) {
                    return res.url
                }
            } catch (err) {
                console.error(err)
            }
        },
        async initDesignHuddle(){
            
            if(this.scriptTag) return
            
            if(!this.userAccessToken) {
                console.error('NO ACCESS TOKEN FOR DH')
                return
            }
            
            const loadScript = (url) => {
                return new Promise(function(resolve, reject) {
                    var script = document.createElement("script");
                    script.onload = () => resolve(script);
                    script.onerror = reject;
                    script.src = url;
                    document.head.appendChild(script);
                });
            }
    
            const designHuddleScriptTag = await loadScript("https://cdn.designhuddle.com/editor/v1/lib.js")
    
            DSHDEditorLib.configure({
                domain: "cartkit.designhuddle.com", 
                access_token: this.userAccessToken 
            });
            this.scriptTag = designHuddleScriptTag
    
        },
        injectDesignHuddle({elementId, projectId}){
            if(!this.userAccessToken) {
                console.error('NO ACCESS TOKEN FOR DESIGN HUDDLE')
                return
            }
    
            this.activeEditor?.remove()
            const editor = DSHDEditorLib.insert(elementId, { 
                project_id: projectId
            });
            this.activeEditor = editor
        },
    
        changeEditorProject({projectId}){
            this.activeEditor.changeProject(projectId)
        },
    }
})